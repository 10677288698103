import { string, object, array, boolean } from 'yup';

const ContactInformationSchema = object().shape({
  // index 0, first form
  name: string().required('Your name is required.'),
  emailAddress: string()
    .trim()
    .email('Please enter a valid email address')
    .required('Your email address is required'),
  mobileNumber: string()
    .min(6, 'Please enter a valid phone/mobile number')
    .matches(/^[^a-zA-Z]*$/, 'Please enter a valid phone/mobile number')
    .required('Your phone/mobile number is required'),
  companyName: string(),
  natureOfBusiness: string().when('companyName', {
    is: val => !!val,
    then: string().required('This field is required'),
    otherwise: string(),
  }),
  designation: string().when('companyName', {
    is: val => !!val,
    then: string().required('This field is required'),
    otherwise: string(),
  }),
});

export const OwnerDeveloperTarget = [
  'First-time developer',
  'Experienced developer',
  'Individual property owner',
  'Corporate property owner',
];

export const InvestorTarget = [
  'Individual seeking to make a property investment',
  'Corporation seeking to purchase property',
  'Individual investor looking for joint venture',
  'Asset/Portfolio Manager',
];

const IndustryPositionSchema = object().shape({
  // index 0, first form
  industryIdentification: array(string()).required('This field is required'),
  portfolioComposition: array().when('industryIdentification', {
    is: val => OwnerDeveloperTarget.some(v => val.includes(v)), // if the answer from industryIdentification matches one of the targets, require this question
    then: array(string()).required('This field is required'),
    otherwise: array(string()),
  }),
  areasOfOperation: array(string()).required('This field is required'),
});

const ClientTargetSchema = object()
  .shape({
    propertyToFocus: array(string()).when('industryIdentification', {
      is: val => OwnerDeveloperTarget.some(v => val.includes(v)), // if the answer from industryIdentification matches one of the targets, require this question
      then: array(string()).required('This field is required'),
      otherwise: array(string()),
    }),
    stageOfDevelopment: array(string()).when('industryIdentification', {
      is: val => OwnerDeveloperTarget.some(v => val.includes(v)), // if the answer from industryIdentification matches one of the targets, require this question
      then: array(string()).required('This field is required'),
      otherwise: array(string()),
    }),
    areasToExplore: array(string()).when('industryIdentification', {
      is: val => InvestorTarget.some(v => val.includes(v)), // if the answer from industryIdentification matches one of the targets, require this question
      then: array(string()).required('This field is required'),
      otherwise: array(string()),
    }),
    pursueInArea: array(string()).when('industryIdentification', {
      is: val => InvestorTarget.some(v => val.includes(v)), // if the answer from industryIdentification matches one of the targets, require this question
      then: array(string()).required('This field is required'),
      otherwise: array(string()),
    }),
  })
  .concat(IndustryPositionSchema);

const AdditionalSchema = object().shape({
  brandAwareness: array(string()),
  firstTime: boolean(),
  radarSubscribe: boolean(),
  recaptcha: string()
    .nullable()
    .required("Please confirm you're not a robot."),
});

const schemaArray = [
  ContactInformationSchema,
  IndustryPositionSchema,
  ClientTargetSchema,
  AdditionalSchema,
];

export const AreasOfOperationOptions = [
  {
    label: 'Makati CBD',
    value: 'Makati CBD',
  },
  {
    label: 'Taguig CBD',
    value: 'Taguig CBD',
  },
  {
    label: 'Ortigas Center',
    value: 'Ortigas Center',
  },
  {
    label: 'Bay City',
    value: 'Bay City',
  },
  {
    label: 'Alabang',
    value: 'Alabang',
  },
  {
    label: 'Clark',
    value: 'Clark',
  },
  {
    label: 'Cavite',
    value: 'Cavite',
  },
  {
    label: 'Davao',
    value: 'Davao',
  },
  {
    label: 'Cebu',
    value: 'Cebu',
  },
  {
    label: 'Elsewhere in the Philippines',
    value: 'Elsewhere in the Philippines',
  },
  {
    label: 'Overseas',
    value: 'Overseas',
  },
];

export const IdentificationOptions = [
  { label: 'First-time developer', value: 'First-time developer' },
  { label: 'Experienced developer', value: 'Experienced developer' },
  { label: 'Individual property owner', value: 'Individual property owner' },
  { label: 'Corporate property owner', value: 'Corporate property owner' },
  {
    label: 'Individual seeking to make a property investment',
    value: 'Individual seeking to make a property investment',
  },
  {
    label: 'Corporation seeking to purchase property',
    value: 'Corporation seeking to purchase property',
  },
  {
    label: 'Individual investor looking for joint venture',
    value: 'Individual investor looking for joint venture',
  },
  { label: 'Asset/Portfolio Manager', value: 'Asset/Portfolio Manager' },
];

export const NatureOfBusinessOptions = [
  { label: 'Advertising / Marketing', value: 'Advertising / Marketing' },
  { label: 'Accomodation / Tourism', value: 'Accomodation / Tourism' },
  { label: 'Agricultural / Rural', value: 'Agricultural / Rural' },
  {
    label: 'Automotive / Transportation',
    value: 'Automotive / Transportation',
  },
  { label: 'Beauty & Health', value: 'Beauty & Health' },
  { label: 'BPO', value: 'BPO' },
  { label: 'Building / Construction', value: 'Building / Construction' },
  { label: 'Education / Training', value: 'Education / Training' },
  { label: 'Energy & Utilities', value: 'Energy & Utilities' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'Franchising Business', value: 'Franchising Business' },
  { label: 'General Services', value: 'General Services' },
  { label: 'Home & Garden', value: 'Home & Garden' },
  {
    label: 'Import, Export or Wholesale',
    value: 'Import, Export or Wholesale',
  },
  { label: 'Industrial Manufacturing', value: 'Industrial Manufacturing' },
  { label: 'Leisure / Entertainment', value: 'Leisure / Entertainment' },
  { label: 'Logistics / Distribution', value: 'Logistics / Distribution' },
  { label: 'Metals & Mining', value: 'Metals & Mining' },
  { label: 'Professional Services', value: 'Professional Services' },
  { label: 'Retail and Trading', value: 'Retail and Trading' },
  {
    label: 'Technology / Telecommunications',
    value: 'Technology / Telecommunications',
  },
  // Other: *[blank]*
];

export const PortfolioCompositionOptions = [
  {
    label:
      'Lot intended for development (either vacant or with existing structure)',
    value:
      'Lot intended for development (either vacant or with existing structure)',
  },
  {
    label:
      'Lot intended for land lease or sale (either vacant or with existing structure)',
    value:
      'Lot intended for land lease or sale (either vacant or with existing structure)',
  },
  {
    label:
      'Property Development currently under construction (either commercial, office, retail, or industrial)',
    value:
      'Property Development currently under construction (either commercial, office, retail, or industrial)',
  },
  {
    label: 'Operational property with low occupancy rate',
    value: 'Operational property with low occupancy rate',
  },
  {
    label: 'Stagnant property',
    value: 'Stagnant property',
  },
  {
    label: 'Other than the above',
    value: 'Other than the above',
  },
];

export const PursueInAreaOptions = [
  {
    label:
      'Lot development and eventual lease or sale (either vacant or with an existing structure)',
    value:
      'Lot development and eventual lease or sale (either vacant or with an existing structure)',
  },
  {
    label: 'Maximizing the returns of an existing property development',
    value: 'Maximizing the returns of an existing property development',
  },
  {
    label:
      'Solely for land-banking purposes (either vacant or with an existing structure)',
    value:
      'Solely for land-banking purposes (either vacant or with an existing structure)',
  },
  {
    label: 'I am seeking further recommendations.',
    value: 'I am seeking further recommendations.',
  },
];

export const cleanUpValues = values => {
  // return cleaned values object, removing fields that are no longer valid for this submission.
  // This may occur e.g. when the user goes back to a different part of the
  // questionnaire and selects a different answer, changing the structure of the form.
  const newValues = [
    { key: 'Name', value: values.name },
    { key: 'Email Address', value: values.emailAddress },
    { key: 'Mobile number', value: values.mobileNumber },
    // { key: 'recaptcha', value: values.recaptcha },
    {
      key: 'Where did you learn of PRIME?',
      value: values.brandAwareness.join(', '),
    },
    {
      key: 'Is this your first time doing business with us?',
      value: values.firstTime,
    },
    { key: 'Subscribe to our Radar newsletter?', value: values.radarSubscribe },
  ];

  if (values.companyName.trim() !== '') {
    newValues.push({
      key: 'Company Name',
      value: values.companyName,
    });
    newValues.push({
      key: 'Designation',
      value: values.designation,
    });
    newValues.push({
      key: 'Nature of Business',
      value: values.natureOfBusiness,
    });
  }

  newValues.push({
    key: 'How would you identify yourself?',
    value: values.industryIdentification.join(', '),
  });
  newValues.push({
    key: 'What area/s are you currently operating in?',
    value: values.areasOfOperation.join(', '),
  });

  if (
    OwnerDeveloperTarget.some(v => values.industryIdentification.includes(v))
  ) {
    newValues.push({
      key: 'What kinds of properties or developments comprise your portfolio?',
      value: values.portfolioComposition.join(', '),
    });
    newValues.push({
      key:
        'What kind of property do you intend to focus on with the help of PRIME Philippines? ',
      value: values.propertyToFocus.join(', '),
    });
    newValues.push({
      key:
        'At what stage of development is the aforementioned property currently in?',
      value: values.stageOfDevelopment.join(', '),
    });
  }

  if (InvestorTarget.some(v => values.industryIdentification.includes(v))) {
    newValues.push({
      key: 'What area/s are you most interested in exploring/expanding to? ',
      value: values.areasToExplore.join(', '),
    });
    newValues.push({
      key: 'What do you intend to pursue in the areas you are interested in?',
      value: values.pursueInArea.join(', '),
    });
  }

  return { data: newValues, email_address: values.emailAddress };
};

export default schemaArray;
