import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import SeasonedPageComponent from '../../components/expertise-page/seasoned/SeasonedPageComponent';
import ExpertiseHeroComponent from '../../components/expertise-page/ExpertiseHeroComponent';
import WhyChoosePRIMEComponent from '../../components/expertise-page/WhyChoosePRIMEComponent';

const StyledDivWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 50px;
  h3 {
    margin-bottom: 0px;
  }

  .strong-description {
    margin: 50px 0px;
    text-align: center;
  }
`;

function OurExpertiseSeasonedPage({ location }) {
  // const data = useStaticQuery(graphql`
  //   {
  //     allStrapiServices(
  //       filter: { visible: { eq: true }, service_type: { eq: "Seasoned" } }
  //     ) {
  //       nodes {
  //         strapiId
  //         category
  //         description
  //         service_client_details
  //         service_name
  //         service_type
  //         strapiId
  //         tags
  //         url_slug
  //         visible
  //         thumb_photo
  //         banner_photo
  //       }
  //     }
  //   }
  // `);

  return (
    <Layout>
      <SEO
        title="Our Expertise - Architect your breakthrough"
        description="Our real estate services made for your specific needs, a personalized and tailor-fit approach to accommodate your preferences and expectations. Help us understand your needs and we'll craft a comprehensive solution to ensure your success!"
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'real estate services, Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <StyledDivWrapper>
        <ExpertiseHeroComponent serviceStage="seasoned" />
        <WhyChoosePRIMEComponent />
        <SeasonedPageComponent />
      </StyledDivWrapper>
    </Layout>
  );
}

OurExpertiseSeasonedPage.propTypes = {
  location: PropTypes.object,
};

export default OurExpertiseSeasonedPage;
