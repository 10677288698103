import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Input, Select } from 'antd';
import { Field } from 'formik';

import { NatureOfBusinessOptions } from './schema';

const ContactInformationPanel = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
}) => (
  <Row
    type="flex"
    gutter={[30, 10]}
    className="contact-information questionnaire-panel"
  >
    <Col
      sm={24}
      xs={24}
      md={18}
      lg={18}
      xl={18}
      xxl={18}
      className="questionnaire-direction"
    >
      Fields marked with (<span className="required-asterisk">*</span>) are
      required.
    </Col>
    <Col
      sm={24}
      xs={24}
      md={18}
      lg={18}
      xl={18}
      xxl={18}
      className="questionnaire-fields"
    >
      <label htmlFor="name" className="form-labels">
        Name <span className="required-asterisk">*</span>
      </label>
      <Field
        id="name"
        name="name"
        placeholder="First and last name"
        size="large"
        className={`input-field ${touched.name && errors.name ? 'error' : ''}`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        as={Input}
      />
      {touched.name && errors.name ? (
        <span className="errors-span">{errors.name}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      className="questionnaire-fields"
    >
      <label htmlFor="email_address" className="form-labels">
        E-mail Address <span className="required-asterisk">*</span>
      </label>
      <Field
        id="email_address"
        name="emailAddress"
        placeholder="name@domain.com"
        size="large"
        type="email"
        className={`input-field ${
          touched.emailAddress && errors.emailAddress ? 'error' : ''
        }`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.emailAddress}
        as={Input}
      />
      {touched.emailAddress && errors.emailAddress ? (
        <span className="errors-span">{errors.emailAddress}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      className="questionnaire-fields"
    >
      <label htmlFor="mobile_number" className="form-labels">
        Phone/mobile number <span className="required-asterisk">*</span>
      </label>
      <Field
        id="mobile_number"
        name="mobileNumber"
        placeholder="+639056512333"
        size="large"
        type="tel"
        className={`input-field ${
          touched.mobileNumber && errors.mobileNumber ? 'error' : ''
        }`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.mobileNumber}
        as={Input}
      />
      {touched.mobileNumber && errors.mobileNumber ? (
        <span className="errors-span">{errors.mobileNumber}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={18}
      lg={18}
      xl={18}
      xxl={18}
      className="questionnaire-fields"
    >
      <label htmlFor="company_name" className="form-labels">
        Company (if applicable)
      </label>
      <Field
        id="company_name"
        name="companyName"
        placeholder=""
        size="large"
        className={`input-field ${
          touched.companyName && errors.companyName ? 'error' : ''
        }`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.companyName}
        as={Input}
      />
      {touched.companyName && errors.companyName ? (
        <span className="errors-span">{errors.companyName}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      className="questionnaire-fields"
    >
      <label htmlFor="nature_of_business" className="form-labels">
        Nature of Business{' '}
        {values.companyName ? <span className="required-asterisk">*</span> : ''}
      </label>
      <Field
        id="nature_of_business"
        name="natureOfBusiness"
        size="large"
        placeholder="Select one..."
        disabled={!values.companyName}
        className={`input-field ${
          touched.natureOfBusiness && errors.natureOfBusiness ? 'error' : ''
        }`}
        onChange={value => {
          setFieldValue('natureOfBusiness', value);
        }}
        onBlur={handleBlur}
        defaultValue={values.natureOfBusiness}
        options={NatureOfBusinessOptions}
        as={Select}
      />
      {touched.natureOfBusiness && errors.natureOfBusiness ? (
        <span className="errors-span">{errors.natureOfBusiness}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      className="questionnaire-fields"
    >
      <label htmlFor="designation" className="form-labels">
        Designation{' '}
        {values.companyName ? <span className="required-asterisk">*</span> : ''}
      </label>
      <Field
        id="designation"
        name="designation"
        size="large"
        disabled={!values.companyName}
        className={`input-field ${
          touched.designation && errors.designation ? 'error' : ''
        }`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.designation}
        as={Input}
      />
      {touched.designation && errors.designation ? (
        <span className="errors-span">{errors.designation}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-buttons"
    >
      <Button
        type="primary"
        htmlType="submit"
        className="next-button"
        size="large"
      >
        Next &gt;
      </Button>
    </Col>
  </Row>
);

ContactInformationPanel.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default ContactInformationPanel;
