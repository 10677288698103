import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox, Button } from 'antd';
import { Field } from 'formik';

import {
  OwnerDeveloperTarget,
  InvestorTarget,
  AreasOfOperationOptions,
  PortfolioCompositionOptions,
  PursueInAreaOptions,
} from './schema';

const { Group: CheckboxGroup } = Checkbox;

const stageOfDevelopmentOptions = [
  {
    value: 'No plans of development',
    label: 'No plans of development [CPI]',
  },
  {
    value: 'With future ideas to develop',
    label: 'With future ideas to develop [RNA]',
  },
  {
    value: 'Preparation and planning stage',
    label: 'Preparation and planning stage [RNA, LR, QSPM]',
  },
  {
    value: 'Currently under construction',
    label: 'Currently under construction [LR, QSPM]',
  },
  {
    value: 'Spaces for lease/sale',
    label: 'Spaces for lease/sale [LR, CPI, PM]',
  },
  {
    value: 'I am unsure how to proceed.',
    label: 'I am unsure how to proceed.',
  },
];

const ClientTargetPanel = ({
  values,
  errors,
  setFieldValue,
  touched,
  backOneStep,
}) => (
  <Row
    type="flex"
    gutter={[30, 10]}
    className="client-target questionnaire-panel"
  >
    {OwnerDeveloperTarget.some(v =>
      values.industryIdentification.includes(v)
    ) ? (
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="property_to_focus" className="form-labels">
          What kind of property do you intend to focus on with the help of PRIME
          Philippines? <span className="required-asterisk">*</span>
        </label>
        <Field
          id="property_to_focus"
          name="propertyToFocus"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.propertyToFocus && errors.propertyToFocus ? 'error' : ''
          }`}
          onChange={value => {
            setFieldValue('propertyToFocus', value);
          }}
          // onBlur={handleBlur}
          value={values.propertyToFocus}
          options={PortfolioCompositionOptions}
          as={CheckboxGroup}
        />
        {touched.propertyToFocus && errors.propertyToFocus ? (
          <span className="errors-span">{errors.propertyToFocus}</span>
        ) : (
          ''
        )}
      </Col>
    ) : (
      ''
    )}
    {OwnerDeveloperTarget.some(v =>
      values.industryIdentification.includes(v)
    ) ? (
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="stage_of_development" className="form-labels">
          At what stage of development is the aforementioned property currently
          in? <span className="required-asterisk">*</span>
        </label>
        <Field
          id="stage_of_development"
          name="stageOfDevelopment"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.stageOfDevelopment && errors.stageOfDevelopment
              ? 'error'
              : ''
          }`}
          onChange={value => {
            setFieldValue('stageOfDevelopment', value);
          }}
          // onBlur={handleBlur}
          value={values.stageOfDevelopment}
          options={stageOfDevelopmentOptions}
          as={CheckboxGroup}
        />
        {touched.stageOfDevelopment && errors.stageOfDevelopment ? (
          <span className="errors-span">{errors.stageOfDevelopment}</span>
        ) : (
          ''
        )}
      </Col>
    ) : (
      ''
    )}
    {InvestorTarget.some(v => values.industryIdentification.includes(v)) ? (
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="areas_to_explore" className="form-labels">
          What area/s are you most interested in exploring/expanding to? Check
          all that apply. <span className="required-asterisk">*</span>
        </label>
        <Field
          id="areas_to_explore"
          name="areasToExplore"
          size="large"
          className={`input-field ${
            touched.areasToExplore && errors.areasToExplore ? 'error' : ''
          }`}
          onChange={value => {
            setFieldValue('areasToExplore', value);
          }}
          // onBlur={handleBlur}
          value={values.areasToExplore}
          options={AreasOfOperationOptions}
          as={CheckboxGroup}
        />
        {touched.areasToExplore && errors.areasToExplore ? (
          <span className="errors-span">{errors.areasToExplore}</span>
        ) : (
          ''
        )}
      </Col>
    ) : (
      ''
    )}
    {InvestorTarget.some(v => values.industryIdentification.includes(v)) ? (
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="pursue_in_area" className="form-labels">
          What area/s are you most interested in exploring/expanding to? Check
          all that apply. <span className="required-asterisk">*</span>
        </label>
        <Field
          id="pursue_in_area"
          name="pursueInArea"
          size="large"
          className={`input-field ${
            touched.pursueInArea && errors.pursueInArea ? 'error' : ''
          }`}
          onChange={value => {
            setFieldValue('pursueInArea', value);
          }}
          // onBlur={handleBlur}
          value={values.pursueInArea}
          options={PursueInAreaOptions}
          as={CheckboxGroup}
        />
        {touched.pursueInArea && errors.pursueInArea ? (
          <span className="errors-span">{errors.pursueInArea}</span>
        ) : (
          ''
        )}
      </Col>
    ) : (
      ''
    )}
    <Col
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-buttons"
    >
      <Button
        type="primary"
        className="prev-button"
        size="large"
        onClick={() => backOneStep()}
      >
        &lt; Previous
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        className="next-button"
        size="large"
      >
        Next &gt;
      </Button>
    </Col>
  </Row>
);

ClientTargetPanel.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  backOneStep: PropTypes.func,
};

export default ClientTargetPanel;
