import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';
import { Row, Col, Radio, Checkbox, Button } from 'antd';
import { Field } from 'formik';

const { Group: RadioGroup } = Radio;
const { Group: CheckboxGroup } = Checkbox;

const brandAwarenessOptions = [
  { label: 'In the news', value: 'In the news' },
  { label: 'Social media posts', value: 'Social media posts' },
  { label: 'Referral/word-of-mouth', value: 'Referral/word-of-mouth' },
  { label: 'Attended an event/webinar', value: 'Attended an event/webinar' },
  { label: 'Online search engines', value: 'Online search engines' },
];

const ConfirmationPanel = ({
  values,
  errors,
  setFieldValue,
  touched,
  backOneStep,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Row
      type="flex"
      gutter={[30, 10]}
      className="confirmation questionnaire-panel"
    >
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="brand_awareness" className="form-labels">
          Where did you learn of PRIME? Check all that apply.{' '}
          <span className="required-asterisk">*</span>
        </label>
        <Field
          id="brand_awareness"
          name="brandAwareness"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.brandAwareness && errors.brandAwareness ? 'error' : ''
          }`}
          onChange={value => {
            setFieldValue('brandAwareness', value);
          }}
          // onBlur={handleBlur}
          value={values.brandAwareness}
          options={brandAwarenessOptions}
          as={CheckboxGroup}
        />
        {touched.brandAwareness && errors.brandAwareness ? (
          <span className="errors-span">{errors.brandAwareness}</span>
        ) : (
          ''
        )}
      </Col>
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="first_time" className="form-labels">
          Is this your first time doing business with us?{' '}
          <span className="required-asterisk">*</span>
        </label>
        <Field
          id="first_time"
          name="firstTime"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.firstTime && errors.firstTime ? 'error' : ''
          }`}
          onChange={e => {
            setFieldValue('firstTime', e.target.value);
          }}
          // onBlur={handleBlur}
          value={values.firstTime}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          as={RadioGroup}
        />
        {touched.firstTime && errors.firstTime ? (
          <span className="errors-span">{errors.firstTime}</span>
        ) : (
          ''
        )}
      </Col>
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="radar_subscribe" className="form-labels">
          Do you want to subscribe to our Radar newsletter?{' '}
          <span className="required-asterisk">*</span>
        </label>
        <Field
          id="radar_subscribe"
          name="radarSubscribe"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.radarSubscribe && errors.radarSubscribe ? 'error' : ''
          }`}
          onChange={e => {
            setFieldValue('radarSubscribe', e.target.value);
          }}
          // onBlur={handleBlur}
          value={values.radarSubscribe}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          as={RadioGroup}
        />
        {touched.radarSubscribe && errors.radarSubscribe ? (
          <span className="errors-span">{errors.radarSubscribe}</span>
        ) : (
          ''
        )}
      </Col>
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <Recaptcha
          sitekey="6LdehiUTAAAAALj4VhxGS0EEMsC6aech1yN8UFfk"
          onChange={value => {
            setFieldValue('recaptcha', value);
          }}
        />
        {touched.recaptcha && errors.recaptcha ? (
          <span className="errors-span">{errors.recaptcha}</span>
        ) : (
          ''
        )}
      </Col>
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-buttons"
      >
        <Button
          type="primary"
          className="prev-button"
          size="large"
          disabled={loading}
          onClick={() => backOneStep()}
        >
          &lt; Previous
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="next-button"
          size="large"
          onClick={() => {
            setLoading(true);
          }}
          loading={loading}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );
};

ConfirmationPanel.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  backOneStep: PropTypes.func,
};

export default ConfirmationPanel;
