import React, { useState } from 'react';
import styled from 'styled-components';
// import Recaptcha from 'react-google-recaptcha';
import { Steps, Row, Col, Card, Progress, notification } from 'antd';
import { Form, Formik } from 'formik';
// import { isValidEmail, isNull } from '../../../utilities/formatChecker';

import schemaArray, { cleanUpValues } from './questionnaire-panels/schema';
import ContactInformationPanel from './questionnaire-panels/ContactInformationPanel';
import IndustryPositioningPanel from './questionnaire-panels/IndustryPositioningPanel';
import ClientTargetPanel from './questionnaire-panels/ClientTargetPanel';
import ConfirmationPanel from './questionnaire-panels/ConfirmationPanel';
import RecommendationsPanel from './questionnaire-panels/RecommendationsPanel';

// polyfills for yup
import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/map';

const { Step } = Steps;

const Wrapper = styled.div`
  padding: 20px 80px;
  max-width: 1440px;
  margin: auto;

  @media only screen and (max-width: 1084px) {
    padding: 20px 40px;
  }
  @media only screen and (max-width: 590px) {
    padding: 20px 20px;
  }

  .com-title {
    color: ${props => props.theme.primeBluePalette.original};
  }

  @media only screen and (max-width: 992px) {
    .step-col {
      margin-bottom: 20px;
      border-bottom: 1px solid rgb(240, 240, 240);
    }
  }
  .progress-bar {
    margin-bottom: 12px;
  }
  .lined {
    border-left: 1px solid rgb(240, 240, 240);
    @media only screen and (max-width: 992px) {
      border-left: none;
      padding: 0 !important;
    }
  }

  .questionnaire-card {
    /* min-height: 450px; */
    .ant-card-body,
    .card-row {
      height: 100%;
    }
  }

  .step .ant-steps-item-title {
    /* font-size: 12px;
    line-height: 14px;
    text-transform: uppercase; */
    font-weight: 600;
    /* letter-spacing: 2px; */
    height: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 1164px) {
      font-size: 14px;
    }
  }

  .questionnaire-panel {
    @media only screen and (max-width: 425px) {
      padding: 0px 10px;
    }
  }
  .form-labels {
    font-weight: 600;
  }

  .questionnaire-direction {
    opacity: 0.7;
  }
  .required-asterisk {
    font-weight: 600;
    color: red;
    font-size: 16px;
    line-height: 12px;
  }

  .questionnaire-buttons {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    /* align-: flex-end; */
    /* justify-content: space-between; */
  }

  .questionnaire-buttons .prev-button,
  .questionnaire-buttons .next-button {
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media only screen and (max-width: 425px) {
      font-size: 16px;
    }
  }

  .questionnaire-buttons .next-button {
    margin-left: auto;
  }

  .errors-span {
    font-size: 12px;
    line-height: 12px;
    opacity: 0.6;
    color: ${props => props.theme.redPalette.original};
  }

  .input-field.error,
  .input-field.error > .ant-select-selector {
    border-color: ${props => props.theme.redPalette.original};
  }

  .ant-select.input-field {
    /* wondering why this isn't the default styling for it */
    width: 100%;
  }

  .full-width-checkbox > .ant-checkbox-group-item,
  .ant-radio-group {
    width: 100%;
  }
`;

const questionnaireShape = {
  name: '',
  emailAddress: '',
  mobileNumber: '',
  companyName: '',
  natureOfBusiness: '',
  designation: '',
  industryIdentification: [],
  portfolioComposition: [],
  areasOfOperation: [],
  propertyToFocus: [],
  stageOfDevelopment: [],
  areasToExplore: [],
  pursueInArea: [],
  brandAwareness: [],
  firstTime: true,
  radarSubscribe: true,
  recaptcha: null,
};

const BreakthroughPageComponent = () => {
  const [currentStep, setStep] = useState(0);

  return (
    <Wrapper>
      <h2 className="com-title">We want to understand your needs better.</h2>
      <p>
        Please fill out this quick questionnaire, and we&apos;ll craft the
        perfect solution for your situation.
      </p>
      <Card className="questionnaire-card">
        <Row type="flex" gutter={[30, 0]} justify="center" className="card-row">
          <Col
            sm={24}
            xs={24}
            md={24}
            lg={6}
            xl={6}
            xxl={6}
            className="step-col"
          >
            <Progress
              className="progress-bar"
              percent={currentStep * 30}
              status="active"
              showInfo={false}
            />
            <Steps
              className="steps"
              current={currentStep}
              size="small"
              direction="vertical"
            >
              <Step className="step" title="Contact information" />
              <Step className="step" title="Where you are" />
              <Step className="step" title="Where you want to go" />
              <Step className="step" title="Confirmation" />
              <Step className="step" title="Thank you" />
              {/* recommendations */}
            </Steps>
          </Col>
          <Col
            className="lined"
            sm={24}
            xs={24}
            md={24}
            lg={18}
            xl={18}
            xxl={18}
          >
            <Formik
              initialValues={questionnaireShape}
              validationSchema={schemaArray[currentStep]}
              onSubmit={values => {
                if (currentStep !== 3) {
                  setStep(currentStep + 1);
                } else {
                  notification.open({
                    message: 'Please wait...',
                    description:
                      "We're taking note of your submission, please give us a moment.",
                  });

                  const data = cleanUpValues(values);
                  data.company_identifier = 'PRIMEPH';
                  data.subject = 'Services (Seasoned - Questionnaire) Inquiry';

                  fetch(`${process.env.STRAPI_API}/email-requests`, {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                  })
                    .then(response => response.json())
                    .then(() => {
                      setStep(currentStep + 1);
                    })
                    .catch(() => {
                      notification.error({
                        message: 'Something went wrong!',
                        description:
                          'There was probleming sending your inquiry. Please try again later.',
                      });
                    });
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  {
                    {
                      0: (
                        <ContactInformationPanel
                          key={0}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          touched={touched}
                        />
                      ),
                      1: (
                        <IndustryPositioningPanel
                          key={1}
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          backOneStep={() => {
                            setStep(currentStep - 1);
                          }}
                        />
                      ),
                      2: (
                        <ClientTargetPanel
                          key={2}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          backOneStep={() => {
                            setStep(currentStep - 1);
                          }}
                        />
                      ),
                      3: (
                        <ConfirmationPanel
                          key={3}
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          backOneStep={() => {
                            setStep(currentStep - 1);
                          }}
                        />
                      ),
                      4: <RecommendationsPanel key={4} values={values} />,
                    }[currentStep]
                  }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default BreakthroughPageComponent;
