import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 60px 20px !important;

  h3 {
    margin-bottom: 12px !important;
  }
  h4 {
    line-height: 24px;
  }
`;

const ConfirmationPanel = () => (
  <Row
    type="flex"
    gutter={[30, 10]}
    className="confirmation questionnaire-panel"
  >
    <StyledCol
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-fields"
    >
      <h3 className="panel-title">
        Thanks for choosing to trust PRIME Philippines for your requirements!
      </h3>
      <h4 className="panel-title">
        Our advisors will be getting in touch with you at the soonest to discuss
        with you how we can help you with our services.
      </h4>
    </StyledCol>
  </Row>
);

ConfirmationPanel.propTypes = {
  // values: PropTypes.object,
};

export default ConfirmationPanel;
