import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox, Button } from 'antd';
import { Field } from 'formik';

import {
  OwnerDeveloperTarget,
  AreasOfOperationOptions,
  PortfolioCompositionOptions,
  IdentificationOptions,
} from './schema';

const { Group: CheckboxGroup } = Checkbox;

const IndustryPositioningPanel = ({
  values,
  errors,
  setFieldValue,
  touched,
  backOneStep,
}) => (
  <Row
    type="flex"
    gutter={[30, 10]}
    className="industry-positioning questionnaire-panel"
  >
    <Col
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-fields"
    >
      <label htmlFor="industry_identification" className="form-labels">
        How would you identify yourself? Check all that apply.{' '}
        <span className="required-asterisk">*</span>
      </label>
      <Field
        id="industry_identification"
        name="industryIdentification"
        size="large"
        className={`input-field full-width-checkbox ${
          touched.industryIdentification && errors.industryIdentification
            ? 'error'
            : ''
        }`}
        onChange={value => {
          setFieldValue('industryIdentification', value);
        }}
        // onBlur={handleBlur}
        value={values.industryIdentification}
        options={IdentificationOptions}
        as={CheckboxGroup}
      />
      {touched.industryIdentification && errors.industryIdentification ? (
        <span className="errors-span">{errors.industryIdentification}</span>
      ) : (
        ''
      )}
    </Col>
    {OwnerDeveloperTarget.some(v =>
      values.industryIdentification.includes(v)
    ) ? (
      <Col
        sm={24}
        xs={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        className="questionnaire-fields"
      >
        <label htmlFor="portfolio_composition" className="form-labels">
          What kinds of properties or developments comprise your portfolio?
          Check all that apply. <span className="required-asterisk">*</span>
        </label>
        <Field
          id="portfolio_composition"
          name="portfolioComposition"
          size="large"
          className={`input-field full-width-checkbox ${
            touched.portfolioComposition && errors.portfolioComposition
              ? 'error'
              : ''
          }`}
          onChange={value => {
            setFieldValue('portfolioComposition', value);
          }}
          // onBlur={handleBlur}
          value={values.portfolioComposition}
          options={PortfolioCompositionOptions}
          as={CheckboxGroup}
        />
        {touched.portfolioComposition && errors.portfolioComposition ? (
          <span className="errors-span">{errors.portfolioComposition}</span>
        ) : (
          ''
        )}
      </Col>
    ) : (
      ''
    )}
    <Col
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-fields"
    >
      <label htmlFor="industry_identification" className="form-labels">
        What area/s are you currently operating in? Check all that apply.{' '}
        <span className="required-asterisk">*</span>
      </label>
      <Field
        id="industry_identification"
        name="areasOfOperation"
        size="large"
        className={`input-field ${
          touched.areasOfOperation && errors.areasOfOperation ? 'error' : ''
        }`}
        onChange={value => {
          setFieldValue('areasOfOperation', value);
        }}
        // onBlur={handleBlur}
        value={values.areasOfOperation}
        options={AreasOfOperationOptions}
        as={CheckboxGroup}
      />
      {touched.areasOfOperation && errors.areasOfOperation ? (
        <span className="errors-span">{errors.areasOfOperation}</span>
      ) : (
        ''
      )}
    </Col>
    <Col
      sm={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="questionnaire-buttons"
    >
      <Button
        type="primary"
        className="prev-button"
        size="large"
        onClick={() => backOneStep()}
      >
        &lt; Previous
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        className="next-button"
        size="large"
      >
        Next &gt;
      </Button>
    </Col>
  </Row>
);

IndustryPositioningPanel.propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  backOneStep: PropTypes.func,
};

export default IndustryPositioningPanel;
